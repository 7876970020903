import React from "react";
import Copyright from "../components/Copyright";
import { StaticImage } from "gatsby-plugin-image"
import { Box, Container, Grid, Paper, Typography,Button } from "@mui/material";

export default function Index() {
  return (
    <Container maxWidth="sm">
      <Box my={4} textAlign="center">
        <Paper style={{ backgroundColor: "#4f4e4e", paddingTop: 15, paddingBottom: 15 }}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Typography variant="h4" component="h1">
              Pablo Lopez&nbsp;
            </Typography>
            <StaticImage src={"../images/computer2.webp"} height={60} width={103}
                        alt="computer guy" placeholder="none"/>
          </Grid>
        </Paper>

        <Box m={5} />
        <Button variant="contained" color="secondary" href="/pablo.pdf" size="large">
          Boring
        </Button>
        <Box m={2} component="span"/>
        <Button variant="contained" color="primary" href="/exciting" size="large">
          Exciting
        </Button>
        <Box m={5}/>
        <Copyright />
      </Box>
    </Container>
  );
}
